<template>
	<Header style="background-color: #2d8cf0;">
		<Menu mode="horizontal" theme="primary">
			<div class="layout-logo">
				<router-link to="/"><h1>{{this.$store.getters.getConfig.sitename}}</h1></router-link>
			</div>
			<div class="layout-nav">
				<MenuItem name="1">
				<LoginModal rel="login"></LoginModal>
				</MenuItem>
				<MenuItem name="2">
				<RegisterModel></RegisterModel>
				</MenuItem>
				<MenuItem v-if="IsLogin" name="5">
				<Button type="info" @click="Logout">
					<Icon type="ios-paper"></Icon>
					退出
				</Button>
				</MenuItem>
				<MenuItem v-if="IsLogin" name="4">
				<Button type="info" to="/center">
					<Icon type="ios-analytics"></Icon>
					用户中心
				</Button>
				</MenuItem>
				<MenuItem name="3">
				<BuyCars></BuyCars>
				</MenuItem>
				<MenuItem v-if="IsLogin" name="7">
					<Space>
						首选语言：
						<Select v-model="GetLangue" @on-change="ChangeLangue">
							<Option value="en">English</Option>
							<Option value="zh">中文</Option>
						</Select>
					</Space>
				</MenuItem>
				<MenuItem v-if="IsLogin" name="6">
				欢迎回来 {{GetProfile.username}} Id:{{GetProfile.id}}
				</MenuItem>
			</div>
		</Menu>
	</Header>
	<main>
		<router-view />
	</main>
	<footer class="ivu-text-center ivu-bt">
		<!--<Row>
			<Col span="18" offset="3">
				<Space split>
					友情连接
					<Link>Link</Link>
					<Link>Link</Link>
					<Link>Link</Link>
				</Space>
			</Col>
		</Row>-->
		<Row>
			<Col span="24">
				<p><b>添加客服微信可加入交流群 客服微信: waimao518666</b></p>
				<p>{{copyright}}</p>
				<p><img src="./assets/wx.8ec6ad77.png" alt="waimao518666" width="80"></p>
			</Col>
		</Row>
	</footer>
	<Modal v-model="ShowLangue" title="设置语言偏好" width="360" @on-cancel="DefaultLangue">
		<Title :level="5">您希望在使用工具时，优先使用哪种语言？</Title>
		<RadioGroup v-model="GetLangue" @on-change="ChangeLangue">
			<Radio label="en">English</Radio>
			<Radio label="zh">中文</Radio>
		</RadioGroup>
	</Modal>
</template>

<style>
	#app .ivu-layout-header {
		height: auto;
	}

	.layout-logo {
		width: 400px;
		float: left;
		overflow: hidden;
	}

	.layout-logo>a {
		color: #fff;
		display: inline-block;
		float: left;
		margin-right: 20px;
	}

	.layout-nav {
		width: 750px;
		float: right;
		text-align: right;
	}


	.layout-nav .ivu-menu-item {
		font-size: 14px;
		float: right !important;
		padding: 0 10px !important;
	}

	.layout-nav .ivu-menu-item a,
	.layout-logo .ivu-menu-item a {
		color: #fff;
	}

	.layout-logo .ivu-menu-item {
		font-size: 14px;
		float: left !important;
		padding: 0 10px !important;
	}


	footer {
		background-color: #f8f8f9;
		padding: 30px 0 !important;
		line-height: 2em;
		height: 235px;
		width: 100%;
	}

	main {
		flex: 1;

	}

	#app {
		min-height: 100%;
		display: flex;
		flex-direction: column;
	}

	html,
	body {
		height: 100% !important;
	}

	.ivu-card-head,
	.ivu-list-header {
		font-weight: bold;
		background-color: rgb(239, 245, 251) !important;
	}

	.ivu-card-head p span {
		font-size: 14px;
		font-weight: bold;
		color: #17233d;
	}

	.ivu-description-term {
		font-weight: bold;
	}
</style>

<script>
	import LoginModal from '@/components/LoginModel.vue'
	import RegisterModel from '@/components/RegisterModel.vue'
	import BuyCars from '@/components/BuyCars.vue'
	//import Cookies from 'js-cookie'
	import Footprint from './assets/footprint.js'
	export default {
		data() {
			return {
				copyright: 'Copyright © 2022 Waimao518 All Rights Reserved',
			}
		},
		components: {
			LoginModal,
			RegisterModel,
			BuyCars
		},
		computed: {
			GetProfile() {
				return this.$store.getters.profile;
			},
			IsLogin() {
				if (this.$store.state.jwt != '') {
					return true;
				} else {
					return false;
				}
			},
			GetLangue(){
				return this.$store.getters.langue;
			},
			ShowLangue(){
				if(this.$store.state.langue == null && this.$store.state.jwt != ''){
					return true;
				} else {
					return false;
				}
			}
		},
		methods: {
			Logout() {
				this.$store.commit('Logout')
				this.$router.push('/')
			},
			ChangeLangue(lg){
				this.$store.commit('SetLangue',lg)
			},
			DefaultLangue(){
				if(!this.$store.getters.langue){
					this.$store.commit('SetLangue','zh')
				}
			}
		},
		created() {
			let CanvasFp = Footprint.getCanvasFp();
			let ip = '';
			let token = Footprint.getAesString({'ip':ip,'footprint':CanvasFp});
			this.$store.commit('SetToken',token)
		}
	}
</script>