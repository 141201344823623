<template>
	<Row>
		<Col span="24" class="ivu-text-center">
		<RadioGroup v-model="type" type="button">
			<Radio label="price">月付</Radio>
			<Radio label="quaprice">季付</Radio>
			<Radio label="yearprice">年付</Radio>
		</RadioGroup>
		</Col>
	</Row>
	<br>
	<Row>
		<Col span="20" offset="2">
			<Row :gutter="16" justify="center">
				<Col span="6" v-for="item in pintai" :key="item.id">
					<Tooltip :content="item.description"  max-width="500">
						<div class="product_item">
							<div class="thumb"><img :src="item.thumb" alt=""></div>
							<div class="info">
								<Title :level="4">{{item.name}}</Title>
								<p>
									<Tag color="error">{{item[type]}}元/{{unit[type]}}</Tag>
									<Tag color="error" v-if="item.limitdesc">{{item.limitdesc}}</Tag>
								</p>
								<Button class="ivu-mt-8" @click="add_to_cars(item)">加入购物车</Button>
							</div>
						</div>
					</Tooltip>
				</Col>
			</Row>
		</Col>
	</Row>
	
</template>

<script>
	export default {
		data() {
			return {
				type: 'price',
				unit: {price:'月',quaprice:'季',yearprice:'年'},
				pintai: []
			}
		},
		created() {
			this.$axios.get('/index/pintailist').then((resp)=>{
				this.pintai = resp.data
			})
		},
		methods:{
			add_to_cars(item) {
				if (this.IsLogin != true) {
					this.$Message.error({content:"请先登录，再进行操作"});
				} else {
					let key = `car-platform-${this.type}-${item.id}`;
					if (key in this.$store.state.cars) {
						this.$Message.error({content:"该平台已经在购物车了"});
					} else {
						let unitarr = {price:"MONTH",quaprice:"QUARTER",yearprice:"YEAR"}
						this.$store.commit('AddCars', {
							'key': key,
							'product': {
								unit:unitarr[this.type],
								price: item[this.type],
								thumb: item.thumb,
								platform: item.name,
								per: item.perlimit
							}
						})
						this.$Message.success({content:"添加成功"});
					}
				}
			},
		},
		computed: {
			IsLogin() {
				if(this.$store.state.jwt != ''){
					return true;
				}else{
					return false;
				}
			}
		}
	}
</script>

<style>
	.product_item {
		background-color: rgb(239, 245, 251);
		margin-bottom: 10px;
		overflow: hidden;
		padding: 10px;
	}
	
	.product_item .thumb{
		float: left;
	}
	
	.product_item .info{
		padding: 10px;
		float: left;
		position: relative;
	}
</style>