import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Cookies from 'js-cookie'

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/center',
		name: 'center',
		meta: {
			requireAuth: true,
			title: '用户中心'
		},
		component: () => import('../views/CenterView.vue')
	},
	{
		path: '/billing',
		name: 'billing',
		meta: {
			requireAuth: true,
			title: '订单记录'
		},
		component: () => import('../views/BillingView.vue')
	},
	{
		path: '/reset',
		name: 'reset',
		meta: {
			requireAuth: true,
			title: '重置密码'
		},
		component: () => import('../views/ResetView.vue')
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			title: '用户登录'
		},
		component: () => import('../views/LoginView.vue')
	},
	{
		path: '/success',
		name: 'success',
		meta: {
			requireAuth: true,
			title: '支付成功'
		},
		component: () => import('../views/SuccessView.vue')
	},
	{
		path: '/payment/:id',
		name: 'payment',
		meta: {
			requireAuth: true,
			title: '支付订单'
		},
		component: () => import('../views/PayView.vue')
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach((to, from, next) => {
	window.document.title = to.meta.title == undefined ? '最好的外贸工具团购平台' : to.meta.title
	if (to.meta.requireAuth) {
		if (Cookies.get('WNJWT')) {
			next();
		} else {
			next('/login');
		}
	} else {
		next();
	}
})

export default router