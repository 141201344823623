import * as CryptoJS from 'crypto-js'
export default {
	getCanvasFp() {
		var result = [];
		var canvas = document.createElement("canvas");
		canvas.width = 2000;
		canvas.height = 200;
		canvas.style.display = "inline";
		var ctx = canvas.getContext("2d");
		ctx.rect(0, 0, 10, 10);
		ctx.rect(2, 2, 6, 6);
		result.push("canvas winding:" + ((ctx.isPointInPath(5, 5, "evenodd") === false) ? "yes" : "no"));
		ctx.textBaseline = "alphabetic";
		ctx.fillStyle = "#f60";
		ctx.fillRect(125, 1, 62, 20);
		ctx.fillStyle = "#069";
		ctx.font = "11pt Arial";
		ctx.fillText("waimao518 canvas footprints, \ud83d\ude03", 2, 15);
		ctx.fillStyle = "rgba(102, 204, 0, 0.2)";
		ctx.font = "18pt Arial";
		ctx.fillText("the best webtools groupbug platform, \ud83d\ude03", 4, 45);
		ctx.globalCompositeOperation = "multiply";
		ctx.fillStyle = "rgb(255,0,255)";
		ctx.beginPath();
		ctx.arc(50, 50, 50, 0, Math.PI * 2, true);
		ctx.closePath();
		ctx.fill();
		ctx.fillStyle = "rgb(0,255,255)";
		ctx.beginPath();
		ctx.arc(100, 50, 50, 0, Math.PI * 2, true);
		ctx.closePath();
		ctx.fill();
		ctx.fillStyle = "rgb(255,255,0)";
		ctx.beginPath();
		ctx.arc(75, 100, 50, 0, Math.PI * 2, true);
		ctx.closePath();
		ctx.fill();
		ctx.fillStyle = "rgb(255,0,255)";
		ctx.arc(75, 75, 75, 0, Math.PI * 2, true);
		ctx.arc(75, 75, 25, 0, Math.PI * 2, true);
		ctx.fill("evenodd");
		result.push("canvas fp:" + canvas.toDataURL());
		let footprint = CryptoJS.MD5(result.join("~")).toString();
		return footprint
	},
	getIPs() {
		var promise = new Promise(function(resolve, reject) {
			var ip_dups = {};
			var RTCPeerConnection = window.RTCPeerConnection ||
				window.mozRTCPeerConnection ||
				window.webkitRTCPeerConnection;
			if (!RTCPeerConnection) {
				var iframe = document.createElement('iframe');
				//invalidate content script
				iframe.sandbox = 'allow-same-origin';
				iframe.style.display = 'none';
				document.body.appendChild(iframe);
				var win = iframe.contentWindow;
				window.RTCPeerConnection = win.RTCPeerConnection;
				window.mozRTCPeerConnection = win.mozRTCPeerConnection;
				window.webkitRTCPeerConnection = win.webkitRTCPeerConnection;
				RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window
					.webkitRTCPeerConnection;
			}
			var mediaConstraints = {
				optional: [{
					RtpDataChannels: true
				}]
			};
			var servers = {
				iceServers: [{
					urls: [
						'stun:stun1.l.google.com:19302',
						'stun:stun2.l.google.com:19302',
						'stun:stun3.l.google.com:19302',
						'stun:stun4.l.google.com:19302',
						'stun:23.21.150.121',
						'stun:stun01.sipphone.com',
						'stun:stun.ekiga.net',
						'stun:stun.fwdnet.net',
						'stun:stun.ideasip.com',
						'stun:stun.iptel.org',
						'stun:stun.rixtelecom.se',
						'stun:stun.schlund.de',
						'stun:stunserver.org',
						'stun:stun.softjoys.com',
						'stun:stun.voiparound.com',
						'stun:stun.voipbuster.com',
						'stun:stun.voipstunt.com',
						'stun:stun.voxgratia.org',
						'stun:stun.xten.com'
					]
				}]
			};
			var pc = new RTCPeerConnection(servers, mediaConstraints);

			function handleCandidate(candidate) {
				var ip_regex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
				var ip_addr = ip_regex.exec(candidate) && ip_regex.exec(candidate)[1];
				if (!ip_addr) return;
				if (ip_dups[ip_addr] === undefined)
					resolve(ip_addr);
				ip_dups[ip_addr] = true;
			}
			pc.onicecandidate = function(ice) {
				if (ice.candidate)
					handleCandidate(ice.candidate.candidate);
			};
			pc.createDataChannel("");
			pc.createOffer(function(result) {
				pc.setLocalDescription(result, function() {}, function() {});
			}, function() {});
			setTimeout(function() {
				try{
					var lines = pc.localDescription.sdp.split('\n');
					lines.forEach(function(line) {
						if (line.indexOf('a=candidate:') === 0)
							handleCandidate(line);
					});
				}catch(e){
					console.log(e)
					reject('webrtc is noe available');
				}
			}, 1000);
		});
		return promise;
	},
	getAesString(data) {
		var key_hash = CryptoJS.MD5(String.fromCharCode(116, 104, 101, 32, 98, 101, 115, 116, 32, 119, 101, 98, 32, 116,
			111, 111, 108, 115, 32, 103, 111, 117, 114, 112, 98, 117, 121, 32, 112, 108, 97, 116, 102, 111, 114,
			109)).toString();
		var key = CryptoJS.enc.Utf8.parse(key_hash);
		var iv = CryptoJS.enc.Utf8.parse('1234567890123456');
		var encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
			iv: iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});
		return encrypted.toString();
	}
}